import * as d3 from "d3";
import {DateTime, Duration, Interval} from "luxon";

/* eslint-disable */
/**
 * @author Dimitry Kudrayvtsev
 * @version 2.0
 *
 * Ported to d3 v4 by Keyvan Fatehi on October 16th, 2016
 *
 */

export default function ganttBoxPlot(parentElement, elementId, dayOfStudyData = {}, options = {}) {
  var FIT_TIME_DOMAIN_MODE = "fit";
  var MIN_EVENT_WIDTH = 1;

  var margin = {
    top: 20,
    right: 30,
    bottom: 20,
    left: 60,
  };

  if (parentElement.id === "heartRateTrendContainer") {
    // Margins for Generated Report
    margin.right = 10;
    margin.left = 50;
  }
  var timeDomainStart = DateTime.now().minus({days: 3});
  var timeDomainEnd = DateTime.now().plus({hours: 3});
  var timeDomainMode = FIT_TIME_DOMAIN_MODE; // fixed or fit
  var maxValueY = 0;
  // Default is Daily Trend Values
  var boxOffsetMs = 2 * 60 * 1000;
  var boxWidthMs = 26 * 60 * 1000;
  var tickFrequency = d3.utcHour.every(1);
  var tickFormat = d3.timeFormat("%H:%M");
  var labelFrequency = d3.utcHour.every(4);

  var height = 240;
  var width = parentElement.offsetWidth - margin.right - margin.left - 20;
  var x, y, xAxis, yAxis;

  initAxis();

  function initAxis() {
    x = d3
      .scaleUtc()
      .domain([timeDomainStart.toJSDate(), timeDomainEnd.toJSDate()])
      .range([0, width])
      .clamp(true);

    y = d3
      .scaleLinear()
      .domain([0, maxValueY])
      .range([height - margin.top - margin.bottom, 0]);

    xAxis = d3.axisBottom().ticks(labelFrequency).scale(x).tickFormat(tickFormat).tickSize(0);

    yAxis = d3.axisLeft().scale(y).tickSize(2);
  }

  function ganttBoxPlot(
    boxPlotDataSets,
    averageHeartRates,
    eventsToExclude,
    showRawFunc,
    isDisabled = false
  ) {
    const disabledClass = isDisabled ? " disabled" : "";
    initAxis();

    const y1 = y.range()[1]; // Bottom
    const y2 = y.range()[0]; // Top

    boxPlotDataSets.forEach(function getDataAndIntervals(boxPlot) {
      // If the data is all zeros, don't display a box plot.
      if (boxPlot.boxPlotPoints[0] === 0 && boxPlot.boxPlotPoints[4] === 0) {
        boxPlot.showData = false;
      } else {
        boxPlot.showData = true;
      }
    });

    var svg = d3
      .select(`#${elementId}`)
      .append("svg")
      .attr("class", "chart")
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      // Subtract 27 from the right margin so that the chart lines up with the arrhythmia timeline
      .attr(
        "viewBox",
        `0 0 ${width + margin.left + margin.right - 27} ${height + margin.top + margin.bottom}`
      )
      .classed("ganttChartSvg", true)
      .append("g")
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .attr("transform", `translate(${margin.left}, ${margin.top})`)
      .attr("preserveAspectRatio", "none");

    // Make vertical gridlines
    svg
      .append("g")
      .attr("class", `grid${disabledClass}`)
      .attr("transform", "translate(0, " + (height - margin.top - margin.bottom) + ")")
      .call(
        _make_x_gridlines()
          .tickSize(-(height - margin.top - margin.bottom))
          .tickFormat("")
      );

    // Make horizontal gridlines
    svg
      .append("g")
      .attr("class", `grid${disabledClass}`)
      .attr("transform", "translate(" + width + ", 0)")
      .call(_make_y_gridlines().tickSize(width).tickFormat(""));

    // Add date lines
    const isDailyTrend = timeDomainEnd - timeDomainStart === Duration.fromObject({days: 1}).toMillis();
    const days = Interval.fromDateTimes(timeDomainStart, timeDomainEnd)
      .splitBy({days: 1})
      .map((i) => (isDailyTrend ? i.end.startOf("day") : i.start));
    days.forEach((day) => {
      let dayOfStudyText = "";

      if (
        dayOfStudyData &&
        Interval.fromDateTimes(dayOfStudyData.firstDay, dayOfStudyData.lastDay).contains(day)
      ) {
        const diffInDays = day.diff(dayOfStudyData.firstDay, "days").toObject().days + 1;
        dayOfStudyText = ` - d${diffInDays}`;
      }

      _showDateLines(svg, x(day.toJSDate()), y1, y2, day, dayOfStudyText, disabledClass);
    });

    // Add in the bars representing artifact (unreadable ECG data)
    eventsToExclude.forEach((unreadableEvent) => {
      let eventWidth = x(new Date(unreadableEvent.endTime)) - x(new Date(unreadableEvent.startTime));
      if (eventWidth < MIN_EVENT_WIDTH) {
        eventWidth = MIN_EVENT_WIDTH;
      }
      svg
        .append("rect")
        .attr("x", x(new Date(unreadableEvent.startTime)))
        .attr("y", y1)
        .attr("width", eventWidth)
        .attr("height", y2)
        .classed(`artifactRegion${disabledClass}`, true);
    });

    // Sets up and shows the average Heart Rate Line
    _showAverageHeartRateLine(svg, averageHeartRates, x, y, disabledClass);

    // Add box plots
    // Only show data that is not all zeros
    const boxPlotDataToDisplay = boxPlotDataSets.filter((boxPlot) => boxPlot.showData);

    // Sets up and shows the lines inside the boxes that represent the median.
    _showMiddleLines(svg, boxPlotDataToDisplay, x, y, disabledClass);

    // Sets up and shows the top whiskers on the box plots using q3 and the max
    _showTopWhiskers(svg, boxPlotDataToDisplay, x, y, disabledClass);

    // Sets up and shows the bottom whiskers on the box plots using the min and q1
    _showBottomWhiskers(svg, boxPlotDataToDisplay, x, y, disabledClass);

    // Sets up and shows the boxes using q1 and q3 values
    _showBoxes(svg, boxPlotDataToDisplay, x, y, disabledClass);

    svg
      .append("g")
      .attr("class", "x axis")
      .attr("transform", "translate(0, " + (height - margin.top - margin.bottom) + ")")
      .transition()
      .call(xAxis);

    svg.append("g").attr("class", "y axis").transition().call(yAxis);

    // Draw the hover indicator rectangle and handle click functionality
    if (showRawFunc) {
      const graphHeight = height - margin.top - margin.bottom;
      const domainWidthMs = timeDomainEnd - timeDomainStart;
      const boxPlotWidthMs = boxPlotDataSets[1].startTime - boxPlotDataSets[0].startTime;
      const numBoxPlotSpaces = domainWidthMs / boxPlotWidthMs;
      const boxWidth = width / numBoxPlotSpaces;
      const xPadding =
        Math.floor((boxPlotDataSets[0].startTime - timeDomainStart) / boxPlotWidthMs) * boxWidth; // Number of X pixels before the first box plot

      // Register mousemove event to show hover-indicator
      svg.on("mousemove", function mouseMoveEvent(event) {
        const coords = d3.pointer(event),
          mouseX = coords[0],
          mouseY = coords[1],
          hoveredIndex = Math.floor((mouseX - xPadding) / boxWidth);

        svg.selectAll(".heart-rate-trend-hover").remove();
        if (
          0 <= hoveredIndex &&
          hoveredIndex < boxPlotDataSets.length &&
          0 <= mouseX &&
          mouseX <= width &&
          0 <= mouseY &&
          mouseY <= graphHeight
        ) {
          svg
            .append("rect")
            .attr("x", boxWidth * hoveredIndex + xPadding)
            .attr("y", 0)
            .attr("width", boxWidth)
            .attr("height", graphHeight)
            .classed("heart-rate-trend-hover", true);
        }
      });

      // Register Click events
      svg.on("click", function mouseClickEvent(event) {
        const coords = d3.pointer(event),
          mouseX = coords[0],
          hoveredIndex = Math.floor((mouseX - xPadding) / boxWidth);
        if (0 <= hoveredIndex && hoveredIndex < boxPlotDataSets.length) {
          showRawFunc(boxPlotDataSets[hoveredIndex].startTime);
        }
      });
    }

    return svg;
  }

  // gridlines in x axis function
  function _make_x_gridlines() {
    return d3.axisBottom(x).ticks(tickFrequency);
  }

  //  gridlines in y axis function
  function _make_y_gridlines() {
    return d3.axisLeft(y);
  }

  // Date Indicators with triangle markers
  function _showDateLines(svg, x1, y1, y2, date, dateText, disabledClass) {
    const color = disabledClass === "" ? "#76858c" : "#c7c7c7";
    const strokeWidth = 2;
    const triangleHeight = 20;
    const triangleWidth = 15;

    svg
      .append("line")
      .attr("stroke", color)
      .attr("stroke-width", strokeWidth)
      .attr("x1", x1)
      .attr("x2", x1)
      .attr("y1", y1 - triangleWidth / 2)
      .attr("y2", y2);
    svg
      .append("text")
      .attr("class", `dateLineText${disabledClass}`)
      .attr("text-anchor", "left")
      .attr("x", x1 + triangleWidth + 5)
      .attr("y", y1 - triangleWidth / 2)
      .text(`${date.toFormat("LLL dd")}${dateText}`);

    // m is pen down, capital means absolute coordinates
    // l is lineto, lowercase means relative coordinates
    // h and v are horizontal and vertical lineto
    let triangleData = `M ${x1 + strokeWidth / 2} 0`;
    triangleData += ` l ${triangleWidth} -${triangleHeight / 2}`;
    triangleData += ` l -${triangleWidth} -${triangleHeight / 2}`;
    triangleData += ` h -${strokeWidth}`;
    triangleData += ` v ${triangleHeight}`;
    svg.append("path").attr("d", triangleData).attr("fill", color);
  }

  function _showBoxes(svg, boxPlotDataArray, xScale, yScale, disabledClass) {
    svg
      .selectAll("plot")
      .data(boxPlotDataArray)
      .enter()
      .append("rect")
      .attr("class", `boxAndWhiskerPlots${disabledClass}`)
      .attr("id", function setId(dataSet) {
        return `bwPlot-${dataSet.startTime}`;
      })
      .attr("x", function getxScale(dataSet) {
        return xScale(dataSet.startTime + boxOffsetMs);
      })
      .attr("width", function getWidth(dataSet) {
        return xScale(dataSet.startTime + boxOffsetMs + boxWidthMs) - xScale(dataSet.startTime + boxOffsetMs);
      })
      .attr("y", function getyScale(dataSet) {
        return yScale(dataSet.boxPlotPoints[3]);
      })
      .attr("height", function getHeight(dataSet) {
        return yScale(dataSet.boxPlotPoints[1]) - yScale(dataSet.boxPlotPoints[3]);
      });

    svg
      .selectAll("plot")
      .data(boxPlotDataArray)
      .enter()
      .append("rect")
      .attr("class", "boxAndWhiskerPlots-clickBox")
      .attr("id", function setId(dataSet) {
        return `bwPlotClickBox-${dataSet.startTime}`;
      })
      .attr("x", function getxScale(dataSet) {
        return xScale(dataSet.startTime + boxOffsetMs);
      })
      .attr("width", function getWidth(dataSet) {
        return xScale(dataSet.startTime + boxOffsetMs + boxWidthMs) - xScale(dataSet.startTime + boxOffsetMs);
      })
      .attr("y", function getyScale(dataSet) {
        return yScale(dataSet.boxPlotPoints[3] + 5);
      })
      .attr("height", function getHeight(dataSet) {
        return yScale(dataSet.boxPlotPoints[1]) - yScale(dataSet.boxPlotPoints[3] + 10);
      });
  }

  function _showMiddleLines(svg, boxPlotDataArray, xScale, yScale, disabledClass) {
    svg
      .selectAll("plotLines")
      .data(boxPlotDataArray)
      .enter()
      .append("line")
      .attr("class", `boxAndWhiskerPlots${disabledClass}`)
      .attr("x1", function getx1(dataSet) {
        return xScale(dataSet.startTime + boxOffsetMs);
      })
      .attr("x2", function getx2(dataSet) {
        return xScale(dataSet.startTime + boxOffsetMs + boxWidthMs);
      })
      .attr("y1", function gety1(dataSet) {
        return yScale(dataSet.boxPlotPoints[2]);
      })
      .attr("y2", function gety2(dataSet) {
        return yScale(dataSet.boxPlotPoints[2]);
      });
  }

  function _showTopWhiskers(svg, boxPlotDataArray, xScale, yScale, disabledClass) {
    svg
      .selectAll("plotLines")
      .data(boxPlotDataArray)
      .enter()
      .append("line")
      .attr("class", `boxAndWhiskerPlots${disabledClass}`)
      .attr("x1", function getx1(dataSet) {
        return xScale(dataSet.startTime + boxOffsetMs + boxWidthMs / 2);
      })
      .attr("x2", function getx2(dataSet) {
        return xScale(dataSet.startTime + boxOffsetMs + boxWidthMs / 2);
      })
      .attr("y1", function gety1(dataSet) {
        return yScale(dataSet.boxPlotPoints[3]);
      })
      .attr("y2", function gety2(dataSet) {
        return yScale(dataSet.boxPlotPoints[4]);
      });
  }

  function _showBottomWhiskers(svg, boxPlotDataArray, xScale, yScale, disabledClass) {
    svg
      .selectAll("plotLines")
      .data(boxPlotDataArray)
      .enter()
      .append("line")
      .attr("class", `boxAndWhiskerPlots${disabledClass}`)
      .attr("x1", function getx1(dataSet) {
        return xScale(dataSet.startTime + boxOffsetMs + boxWidthMs / 2);
      })
      .attr("x2", function getx2(dataSet) {
        return xScale(dataSet.startTime + boxOffsetMs + boxWidthMs / 2);
      })
      .attr("y1", function gety1(dataSet) {
        return yScale(dataSet.boxPlotPoints[0]);
      })
      .attr("y2", function gety2(dataSet) {
        return yScale(dataSet.boxPlotPoints[1]);
      });
  }

  function _showAverageHeartRateLine(svg, averageHeartRates, xScale, yScale, disabledClass) {
    let src;

    averageHeartRates.forEach((point) => {
      let dest;

      // if the average is <= 0, the line segment will not be drawn
      if (point.avg > 0) {
        dest = {
          x: xScale(point.mt), // use the midpoint of the averages
          y: yScale(point.avg),
        };
      }

      // Only Draw the Line Segment if both Source and Destination have
      // valid mean values (skips the first line segment)
      if (src && dest) {
        svg
          .append("line")
          .attr("class", `averageHeartRateLine${disabledClass}`)
          .attr("x1", src.x)
          .attr("y1", src.y)
          .attr("x2", dest.x)
          .attr("y2", dest.y);
      }
      src = dest;
    });
  }

  if (options.hasOwnProperty("maxValueY")) {
    maxValueY = options.maxValueY;
  }

  if (options.hasOwnProperty("boxWidthMs")) {
    boxWidthMs = options.boxWidthMs;
  }

  if (options.hasOwnProperty("boxOffsetMs")) {
    boxOffsetMs = options.boxOffsetMs;
  }

  if (options.hasOwnProperty("tickFrequency")) {
    tickFrequency = options.tickFrequency;
  }

  if (options.hasOwnProperty("tickFormat")) {
    tickFormat = options.tickFormat;
  }

  if (options.hasOwnProperty("labelFrequency")) {
    labelFrequency = options.labelFrequency;
  }

  if (options.hasOwnProperty("timeDomain")) {
    [timeDomainStart, timeDomainEnd] = options.timeDomain;
  }

  /**
   *  @param {string} value The value can be
   *                  "fit" - the domain fits the data or
   *                  "fixed" - fixed domain.
   */
  if (options.hasOwnProperty("timeDomainMode")) {
    timeDomainMode = options.timeDomainMode;
  }

  return ganttBoxPlot;
}
